/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

  




      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
post_type_archive_portfolio: {
  init: function() {
// JavaScript to be fired on the home page
function matrixSortDepth(a, b)
  {
    if(!a || !b) {return 0;}
    return ((a[2] === b[2] && a[0] > b[0]) || a[2] > b[2]) ? 1 : -1;
  }
  function getAttachPoint(mtx, width)
  {
    var _mtx = mtx.concat().sort(matrixSortDepth);
    var _max = _mtx[_mtx.length-1][2];
    for(var i=0,imax=_mtx.length; i<imax; i++)
    {
      if(_mtx[i][2] >= _max){break;}
      if(_mtx[i][1]-_mtx[i][0] >= width)
      {
        return [_mtx[i][0], _mtx[i][2]];
      }
    }
    return [0, _max];
  }
  function matrixTrimWidth(a, b)
  {
    if(a[0] >= b[0] && a[0] < b[1] || a[1] >= b[0] && a[1] < b[1])
    {
      if(a[0] >= b[0] && a[0] < b[1])
      {
        a[0] = b[1];
      }
      else
      {
        a[1] = b[0];
      }
    }
    return a;
  }
    function matrixSortX(a, b)
  {
    if(!a || !b) {return 0;}
    return (a[0] > b[0]) ? 1 : -1;
  }
  function matrixJoin(mtx, cell)
  {
    var _mtx = mtx.concat([cell]).sort(matrixSortX);
    var _mtx_join = [];
    for(var i=0,imax=_mtx.length; i<imax; i++)
    {
      if(!_mtx[i]) {continue;}
      if(_mtx_join.length > 0 && _mtx_join[_mtx_join.length-1][1] === _mtx[i][0] && _mtx_join[_mtx_join.length-1][2] === _mtx[i][2])
      {
        _mtx_join[_mtx_join.length-1][1] = _mtx[i][1];
      }
      else
      {
        _mtx_join.push(_mtx[i]);
      }
    }
    return _mtx_join;
  }
  function updateAttachArea(mtx, point, size)
  {
    var _mtx = mtx.concat().sort(matrixSortDepth);
    var _cell = [point[0], point[0]+size[0], point[1]+size[1]];
    for(var i=0,imax=_mtx.length; i<imax; i++)
    {
      if(_cell[0] <= _mtx[i][0] && _mtx[i][1] <= _cell[1])
      {
        delete _mtx[i];
      }
      else
      {
        _mtx[i] = matrixTrimWidth(_mtx[i], _cell);
      }
    }
    return matrixJoin(_mtx, _cell);
  }
  

  
  
  function strToNum(str){
    var num = parseInt(str, 10);
    if(isNaN(num)){
      return 0;
    }
    return num;
  }
  function getSize(child)
  {
    var _w = child.width();
    var _h = child.height();
    _w += strToNum(child.css("margin-left")) +  strToNum(child.css("padding-left")) +  strToNum(child.css("border-left-width")) +  strToNum(child.css("margin-right")) +  strToNum(child.css("padding-right")) +  strToNum(child.css("border-right-width"));
    _h += strToNum(child.css("margin-top")) +  strToNum(child.css("padding-top")) +  strToNum(child.css("border-top-width")) +  strToNum(child.css("margin-bottom")) +  strToNum(child.css("padding-bottom")) +  strToNum(child.css("border-bottom-width"));
    return [_w, _h];
  }
  
  function getNumValue(val, def){
    def = typeof(def) === "number" && isFinite(def) ? def : 0 ;
    val = typeof(val) === "number" && isFinite(val) ? val : def ;
    return val;
  }
  function heightTo(self)
  {
    var _self = self;
    var _easeTime = getNumValue(_self.data("_vgopt").time, 500);
    var _delay = _self.data("_vgchild").length * (_self.data("_vgopt").delay || 0) + _easeTime;
    _self.stop(false, true);
    if(_self.height() < _self.data("_vgwrapheight"))
    {
      if(!$.support.noCloneEvent)
      {
        _self.height(_self.data("_vgwrapheight"));
      }
      else
      {
        _self.animate(
          {
            height: _self.data("_vgwrapheight")+"px"
          },
          _easeTime,
          "easeOutQuart"
        );
      }
    }
    else
    {
      clearTimeout(_self.data("_vgwraptimeout"));
      _self.data("_vgwraptimeout", setTimeout(function(){
        if(!$.support.noCloneEvent)
        {
          _self.height(_self.data("_vgwrapheight"));
        }
        else
        {
          _self.animate(
            {
              height: _self.data("_vgwrapheight")+"px"
            },
            _easeTime,
            "easeOutQuart"
          );
        }
      }, _delay));
    }
  }
  function moveTo(childs)
  {
    var _c;
    childs.each(function(i)
    {
      _c = $(this);
      _c.css("left", _c.data("_vgleft")+"px");
      _c.css("top", _c.data("_vgtop")+"px");
    });
  }
  function animateTo(childs, easing, time, delay)
  {
    var _self = $(childs).parent();
    var isMove = false;
    var imax = childs.length;
    var i,_c,_pos;
    for(i=0; i<imax; i++)
    {
      _c = $(childs[i]);
      _pos = _c.position();
      if(_pos.left !== _c.data("_vgleft") || _pos.top !== _c.data("_vgtop"))
      {
        isMove = true;
      }
    }
    if(isMove)
    {
      if(typeof(_self.data("_vgopt").onStart) === "function") {_self.data("_vgopt").onStart();}
      childs.each(function(i)
      {
        var _c = $(this);
        var _opt = {
          duration: time,
          easing: easing
        };
        if(childs.size()-1 === i)
        {
          _opt.complete = _self.data("_vgopt").onFinish || null;
        }
        clearTimeout(_c.data("_vgtimeout"));
        _c.data("_vgtimeout", setTimeout(function(){
          _c.animate(
            {
              left: _c.data("_vgleft")+"px",
              top: _c.data("_vgtop")+"px"
            },
            _opt
          );
        }, i*delay));
      });
    }
  }
    function makePos(self)
  {
    var _childs = self.data("_vgchild");
    var _width = self.width();
    var _matrix = [[0,_width,0]];
    var _hmax=0, _c, _size, _point;
    _childs.each(function(i)
    {
      _c = $(this);
      if(_c.css("display") === "none"){
        return true;
      }
      _size = getSize(_c);
      _point = getAttachPoint(_matrix, _size[0]);
      _matrix = updateAttachArea(_matrix, _point, _size);
      _hmax = Math.max(_hmax, _point[1] + _size[1]);
      _c.data("_vgleft", _point[0]);
      _c.data("_vgtop", _point[1]);
    });
    self.data("_vgwrapheight", _hmax);
    heightTo(self);
  }
  function refreshHandler(tg)
  {
    tg.each(function(num){
      var _self = $(this);
      clearTimeout(_self.data("_vgtimeout"));
      _self.data("_vgtimeout", setTimeout(function(){
        makePos(_self);
        animateTo(
          _self.data("_vgchild"),
          _self.data("_vgopt").easing || "linear",
          getNumValue(_self.data("_vgopt").time, 500),
          getNumValue(_self.data("_vgopt").delay, 0)
        );
      }, getNumValue(_self.data("_vgopt").wait, 500)));
    });
  }
  function setFontSizeListener(self, func)
  {
    var s = $("<span />")
      .text(" ")
      .attr("id", "_vgridspan")
      .hide()
      .appendTo("body");
    s.data("size", s.css("font-size"));
    s.data("timer", setInterval(function(){
      if(s.css("font-size") !== s.data("size"))
      {
        s.data("size", s.css("font-size"));
        func(self);
      }
    }, 1000));
  }
  function setImgLoadEvent(self, func)
  {
    if(!self.data("vgrid-image-event-added")){
      self.data("vgrid-image-event-added", 1);
      self.on("vgrid-added", function(){
        self.find("img").each(function(){
          var img = $(this);
          if(!img.data("vgrid-image-handler")){
            img.data("vgrid-image-handler", 1);
            img.on("load", function(){
              func(self);
            });
          }
        });
      });
    }
    self.trigger("vgrid-added");
    var _append = self.append;
    var _prepend = self.prepend;
    self.append = function(){
      _append.apply(self, arguments);
      self.trigger("vgrid-added");
    };
    self.prepend = function(){
      _prepend.apply(self, arguments);
      self.trigger("vgrid-added");
    };
  }
  $.fn.extend({
    vgrid: function(option)
    {
      var _target = $(this);
      var _opt = option || {};
      if (_opt.easeing) {
        _opt.easing = _opt.easeing;
      }
      _target.each(function(){
        var _self = $(this);
        _self.data("_vgopt", _opt);
        _self.data("_vgchild", _self.find("> *"));
        _self.data("_vgdefchild", _self.data("_vgchild"));
        _self.css({
          "position": "relative",
          "width": "auto"
        });
        _self.data("_vgchild").css("position", "absolute");
        makePos(_self);
        moveTo(_self.data("_vgchild"));
        if(_self.data("_vgopt").fadeIn)
        {
          var _prop = (typeof(_self.data("_vgopt").fadeIn) === "object") ? _self.data("_vgopt").fadeIn
                  : {time: _self.data("_vgopt").fadeIn} ;
          _self.data("_vgchild").each(function(i)
          {
            var _c = $(this);
            if(_c.css("display") === "none"){
              return true;
            }
            _c.stop(false, true).css({opacity:0});
            setTimeout(function(){
              _c.stop(false, true).fadeTo(_prop.time || 250, 1);
            }, i * (_prop.delay || 0));
          });
        }
        $(window).resize(function(e)
        {
          refreshHandler(_self);
        });
        if(_opt.useLoadImageEvent) {setImgLoadEvent(_self, refreshHandler);}
        if(_opt.useFontSizeListener) {setFontSizeListener(_self, refreshHandler);}
      });
      return _target;
    },
    vgrefresh: function(easing, time, delay, func)
    {
      var _target = $(this);
      _target.each(function(){
        var _obj = $(this);
        var _opt = _obj.data("_vgopt") || {};
        if(_obj.data("_vgchild"))
        {
          _obj.data("_vgchild", _obj.find("> *"));
          _obj.data("_vgchild").css("position", "absolute");
          makePos(_obj);
          time = getNumValue(time, getNumValue(_obj.data("_vgopt").time, 500));
          delay = getNumValue(delay, getNumValue(_obj.data("_vgopt").delay, 0));
          animateTo(
            _obj.data("_vgchild"),
            easing || _obj.data("_vgopt").easing || "linear",
            time,
            delay
          );
          if(typeof(func) === 'function')
          {
            setTimeout(
              func,
              _obj.data("_vgchild").length * delay + time
            );
          }
        }
        if(_opt.useLoadImageEvent) {setImgLoadEvent(_obj, refreshHandler);}
      });
      return _target;
    },
    vgsort: function(func, easing, time, delay)
    {
      var _target = $(this);
      _target.each(function(){
        var _obj = $(this);
        if(_obj.data("_vgchild"))
        {
          _obj.data("_vgchild", _obj.data("_vgchild").sort(func));
          _obj.data("_vgchild").each(function(num){
            $(this).appendTo(_obj);
          });
          makePos(_obj);
          animateTo(
            _obj.data("_vgchild"),
            easing || _obj.data("_vgopt").easing || "linear",
            getNumValue(time, getNumValue(_obj.data("_vgopt").time, 500)),
            getNumValue(delay, getNumValue(_obj.data("_vgopt").delay, 0))
          );
        }
      });
      return _target;
    }
  });







  var vg = $(".vgrid").vgrid({
    easing: "easeOutQuint",
    useLoadImageEvent: true,
    time: 400,
    delay: 20,
    fadeIn: {
      time: 500,
      delay: 50,
      wait: 500
    }
  });

    var $easyzoom = $('.easyzoom').easyZoom();
function resizeBlankImages() {
  $('.lazy').each(function () {
    var originalWidth = $(this).attr('width');
    var originalHeight = $(this).attr('height');
    var ratioH = originalWidth/originalHeight;
    var width = $(this).width();
    var height = width/ratioH;
    $(this).height(height);
  });
      $('.tab-pane.active .lazy.not-loaded').lazyload({
    effect: 'fadeIn', 
    threshold : 600,
    load: function() {
      $(this).removeClass("not-loaded");
      $(this).addClass("loaded");
    }
  });
      $('.tab-pane.active .description').removeClass("not-visible");
      $('.tab-pane.active .panel').removeClass("not-visible");
}
$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    resizeBlankImages();
        vg.vgrefresh();
        $(window).trigger('scroll');
});
$(window).on("load resize",function(e){
          $(".navbar-collapse").css({ maxHeight: $(window).height() - $(".navbar-header").height() + "px" });
resizeBlankImages();
    vg.vgrefresh();
 });

$('.nav-tabs-dropdown').each(function(i, elm) {  
    $(elm).text($(elm).next('ul').find('li.active a').text());  
});
$('.nav-tabs-dropdown').on('click', function(e) {
    e.preventDefault();
    $(e.target).toggleClass('open').next('ul').slideToggle();
});
$('#tabs a[data-toggle="tab"]').on('click', function(e) {
    e.preventDefault();
    $(e.target).closest('ul').hide().prev('a').removeClass('open').text($(this).text());
});




    $(".modal").each(function() {
      var $this = $(this);
      if(window.location.hash) {
  var hash = window.location.hash;
  $(hash).modal('toggle');
}
      $this.on('show.bs.modal', function(e) { 
        var $owlfull=$this.find('.full.owl-carousel');
        var windowheight=$(window).outerHeight();
        var modalheaderheight=$this.find('.modal-header').outerHeight();
        var modalfooterheight=$this.find('.modal-footer').outerHeight();
        var owlheight= windowheight - 172;
        $owlfull.css('height',owlheight+'px');
        // jQuery method on
$owlfull.on('initialized.owl.carousel',function(property){
  var current = property.item.index;
  var slidenumber = $(property.target).find(".owl-item").eq(current).find("img").attr('data-number');
  $('.num').html(''+slidenumber+'/'+slidenumber+'');
});
$owlfull.on('changed.owl.carousel',function(property){
  var current = property.item.index;
  var slidenumber = $(property.target).find(".owl-item").eq(current).find("img").attr('data-number');
  $('.num').html(''+slidenumber+'');
});
        $owlfull.owlCarousel({
          nav:true,
          margin: 10,
          video:true,
          loop: true,
          items: 1,
          lazyLoad:true,
          animateOut: 'fadeOut',
          animateIn: 'fadeIn',
          navText:['<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>','<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>']
        });
        $(window).on("resize scroll",function(e){
          var windowheight=$(window).outerHeight();
          var modalheaderheight=$('.modal-header').outerHeight();
          var modalfooterheight=$('.modal-footer').outerHeight();
          var owlheight= windowheight - 120;
          $owlfull.css('height',owlheight+'px');
        });
      });
$this.on('hidden.bs.modal', function(e) { 
            history.pushState('', document.title, window.location.pathname);

          });
});



  $('.taphover').click(function (e) {
    window.location.hash = this.hash;
  });

}
},
    // archive page
    'home': {
      init: function() {
        $('.owl-carousel').owlCarousel({
      nav:false,
      loop: true,
      items: 1,
      lazyLoad:true,
      autoplay:true,
      autoplayHoverPause:true,
              animateIn:'fadeIn',
            animateOut: 'fadeOut'
      
    });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
